import { useEffect, useRef } from 'react'
import { useAmp } from 'next/amp'
import { useRouter } from 'next/router'
import config from '../../../config'
import { useTMSHitForHeader } from '../../../hook/useTMSClickHit'
import { isServer } from '../../../helpers/client'
import { theme } from '../../../styles'

// The point of this component is to provide a back arrow in the header
// It will go back to the previous page in the history
// If the history is empty, it will go to the home page
// The history is actually really used only on vertical videos page
export function HeaderBackArrow() {
  const router = useRouter()
  const isAmp = useAmp()
  const { headerHit } = useTMSHitForHeader()
  const previousRoute = useRef([
    !isServer() && document.referrer.includes(config.baseUrl) ? document.referrer : '',
  ])

  function back() {
    // If we have only one route in the history (current page), we go back to the home page
    if (previousRoute.current.length > 1) {
      // Remove the current route and the previous one
      // The current route because we leave it
      // and the previous one because it's the one we are going back to and it will be added again
      previousRoute.current.splice(-2, 2)
      router.back()
    } else {
      router.push('/')
    }
  }

  useEffect(
    function saveHistory() {
      if (window.location.href !== previousRoute.current.at(-1)) {
        previousRoute.current.push(window.location.href)
      }
    },
    [router.asPath],
  )

  const onClick = () => {
    headerHit('fleche-retour')
    back()
  }

  const backArrowSvg = (
    <div className="Header__BackArrow_Wrapper">
      <svg
        className="Header__BackArrow flex items-center"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M19.0398 12.1133H5.0398"
          stroke="var(--color)"
          strokeWidth="2.91667"
          strokeLinejoin="bevel"
        />
        <path
          d="M12.0398 5.11328L5.03979 12.1133L12.0398 19.1133"
          stroke="var(--color)"
          strokeWidth="2.91667"
          strokeLinejoin="bevel"
        />
      </svg>
    </div>
  )

  if (isAmp) return <a href="/">{backArrowSvg}</a>

  return (
    <>
      <div className="Header__BackArrow" onClick={onClick} data-testid="test-arrow">
        {backArrowSvg}
      </div>
      <style jsx>{`
        .Header__BackArrow {
          --color: ${theme.cssVars.white};
          cursor: pointer;
        }
        .Header__BackArrow :global(.Header__BackArrow_Wrapper) {
          padding: 10px;
          border-radius: 50%;
          height: 44px;
          width: 44px;
        }
      `}</style>
    </>
  )
}
