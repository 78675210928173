import type { BlockTypes } from '@etf1-interne/tf1info_types_news'
import type { ComponentType } from 'react'
import type { ISponsorBanner } from '../SponsorBanner'
import type { IPicture } from '../Picture'
import type { IOutdatedBanner } from '../OutdatedBanner'
import type { IArticleBanner } from '../../organisms/ArticleBanner'
import type { IMenuNavigation } from '../../atoms/MenuNavigation'

import { useContext, useEffect, useMemo, useState } from 'react'
import dynamic from 'next/dynamic'
import { useAmp } from 'next/amp'
import { HeaderBackArrow } from '../../atoms/HeaderBackArrow'
import { Bookmark } from '../../atoms/Bookmark/Bookmark'
import { TF1InfoLogo } from '../../atoms/Icon/TF1InfoLogo'
import { PictureElementList } from '../../atoms/PictureElementList'
import { MenuBurgerButton } from '../../atoms/MenuBurgerButton'
import { NotificationsButton } from '../../atoms/NotificationsButton'
import { UserButton } from '../../atoms/UserButton'
import { TabNavigation } from '../../atoms/TabNavigation'
import { HeaderDirectButton } from './HeaderDirectButton'
import { Share } from '../Share'
import { useTMSHitForHeader } from '../../../hook/useTMSClickHit'
import { ApiResponseContext } from '../../../contexts/ApiResponseContext'
import { PAGE_TYPE_CONTENT } from '../../../constants/page'
import { getTabMenu } from '../../../helpers/header'
import { mixins, theme } from '../../../styles'

const ArticleBanner: ComponentType<IArticleBanner> = dynamic(function ArticleBanner() {
  return import('../../organisms/ArticleBanner').then((module) => module.ArticleBanner)
})

const MenuNavigation: ComponentType<IMenuNavigation> = dynamic(function MenuNavigation() {
  return import('../../atoms/MenuNavigation').then((module) => module.MenuNavigation)
})

export interface IHeaderPage {
  eventLogo?: {
    pictures: IPicture
    link: string
  }
  outdatedBanner?: IOutdatedBanner
  sponsor?: ISponsorBanner
  topic?: {
    color: string
  }
  wordings?: {
    login?: string
    direct?: {
      title?: string
      link?: string
    }
  }
  navigation: BlockTypes['header-navigation']['data']['navigation']
}

export function HeaderPage({
  eventLogo,
  outdatedBanner,
  topic,
  wordings,
  navigation,
}: IHeaderPage): JSX.Element {
  const [isReadingMode, setIsReadingMode] = useState(false)
  const { headerHit } = useTMSHitForHeader()
  const { type, page } = useContext(ApiResponseContext)
  const isAmp = useAmp()
  const isContent = type === PAGE_TYPE_CONTENT

  const { direct } = wordings || {}
  const hasNavigationMenu = !!navigation?.length

  function getIntersectionObserver(): IntersectionObserver {
    return new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries

        setIsReadingMode(entry.intersectionRatio === 0)
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: [0, 1.0],
      },
    )
  }

  const hasTabNavigation = useMemo(() => !!getTabMenu(navigation, page.url), [navigation, page.url])

  useEffect(() => {
    if (isContent) {
      const bodyHeaderComponent = document.querySelector("[data-module='body-header']")
      if (bodyHeaderComponent) {
        const intersectionObserver = getIntersectionObserver()
        intersectionObserver.observe(bodyHeaderComponent)

        return () => {
          intersectionObserver.disconnect()
        }
      }
    }
  }, [])

  return (
    <>
      <header
        role="banner"
        aria-label="En-tête principale"
        className={[
          'Header',
          isReadingMode ? 'Header__ReadingMode' : '',
          hasNavigationMenu ? 'Header--navigation' : '',
          hasTabNavigation ? 'Header--tabs' : '',
          isContent ? 'Header--content' : '',
        ]
          .join(' ')
          .trim()}
      >
        <div className="Header__Container flex items-center justify-between">
          <div className="Header__Left flex items-center">
            {!hasNavigationMenu ? <HeaderBackArrow /> : <UserButton />}
            <div className="Header__Logo flex flex-column justify-center items-center">
              <a
                className="Header__Logo_TF1 flex justify-center items-center"
                href="/"
                onClick={() => headerHit('logo')}
                title="Tf1 Info - Retour à l'accueil"
              >
                <TF1InfoLogo size={180} />
              </a>
              {eventLogo && (
                <a href={eventLogo.link} className="Header__EventLogo">
                  <PictureElementList {...eventLogo.pictures} layout="intrinsic" />
                </a>
              )}
            </div>
          </div>
          {hasNavigationMenu ? <MenuNavigation elementList={navigation} /> : null}
          <div className="Header__Right flex items-center">
            {isContent ? (
              <>
                <Share
                  className="Header__Share"
                  size="24px"
                  primaryColor={theme.cssVars.white}
                  tmsId="header_bouton_partage"
                />
                <Bookmark className="Header__Bookmark" />
              </>
            ) : null}
            <UserButton />
            {!hasNavigationMenu ? (
              <HeaderDirectButton link={direct?.link} title={direct?.title} />
            ) : null}
            {!isAmp && <NotificationsButton />}
            <MenuBurgerButton />
          </div>
        </div>
        <TabNavigation elementList={navigation} />
      </header>
      {outdatedBanner ? <ArticleBanner outdatedBanner={outdatedBanner} /> : null}
      <div className="Header__Separator width-100" />

      <style jsx>{`
        .Header__Separator {
          background-color: ${topic?.color ?? theme.cssVars.focusBlue};
        }
      `}</style>
      <style jsx>{`
        .Header {
          background: ${theme.cssVars.headerBackground};
          position: sticky;
          top: 0;
          z-index: 50;
        }

        .Header__Container {
          padding: 16px;
          z-index: 45;
        }

        .Header:not(.Header--content)::after {
          content: ' ';
          position: absolute;
          bottom: -5px;
          left: 0;
          width: 100vw;
          height: 5px;
          background-color: ${theme.cssVars.darkBlue};
        }

        .Header.Header--tabs::after {
          content: none;
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          .Header.Header--tabs::after {
            content: ' ';
          }

          .Header__Container {
            margin: auto;
            max-width: ${theme.layout.maxWidth}px;
          }

          .Header :global(.MenuNavigation) {
            height: 32px;
          }

          .Header :global(.MenuNavigation__List) {
            justify-content: center;
          }

          .Header__Logo {
            flex-direction: row;
            margin: 0 auto 0 0;
          }
        }

        .Header__Logo {
          margin-left: 0;
        }

        .Header__Logo_TF1 {
          height: 32px;
        }

        .Header__EventLogo {
          position: relative;
          display: block;
          overflow: hidden;
          height: 60px;
          min-width: 80px;
        }

        .Header__EventLogo :global(img),
        .Header__EventLogo :global(amp-img),
        .Header__EventLogo :global(svg) {
          height: 60px;
        }

        .Header__Separator {
          display: none;
        }

        .Header--content ~ .Header__Separator {
          display: block;
          height: 4px;
        }

        .Header__Right {
          gap: 20px;
        }

        // Hide direct, share, bookmark, Account and notification buttons on mobile/tablet
        .Header__Right
          :global(:where(.Header__Share, .HeaderDirectButton, .Header__Bookmark, .UserButton, .NotificationsButton)) {
          display: none;
        }

        // Center main logo and keep UserButton on the left
        @media (max-width: ${theme.breakpoints.md}px) {
          .Header--navigation .Header__Left {
            width: 100%;
            display: grid;
            grid-template-columns: 32px 1fr;
          }
        }

        // Display Direct, Account and notification buttons on desktop
        @media ${mixins.mediaQuery.tablet} {
          .Header__Right :global(:where(.HeaderDirectButton, .UserButton, .NotificationsButton)) {
            display: flex;
          }

          .Header__Left :global(.UserButton) {
            display: none;
          }
        }

        .Header__Right :global(:where(.Header__Share, .Header__Bookmark)) {
          cursor: pointer;
          background-color: transparent;
          border: none;
        }

        // After scrolling, display Bookmark, Share and Account buttons
        .Header__ReadingMode :global(:where(.Header__Share, .Header__Bookmark, .UserButton)) {
          display: flex;
        }

        // Only hide Direct, Menu and notification buttons on mobile after scrolling
        @media (max-width: ${theme.breakpoints.md}px) {
          .Header__ReadingMode
            :global(:where(.HeaderDirectButton, .NotificationsButton, .MenuBurgerButton)) {
            display: none;
          }
        }
      `}</style>
    </>
  )
}
